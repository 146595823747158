import { AccessTimeOutlined, InfoOutlined } from '@mui/icons-material';
import { Avatar, Button, ButtonGroup, Dialog, Grid, IconButton, Paper, Tab, Tabs, Tooltip } from '@mui/material';
import { AppLogo } from 'components';
import { useTabs } from 'hooks';
import { useMemo, useState } from 'react';
import { default as Countdown } from 'react-countdown';
import { useSelector } from 'react-redux';
import { systemSelector } from 'reducers/systemSlice';
import { PopupBet, PopupInfo } from './components';
import { ONE_TO_FIVE, TONG_HOA } from './components/games';

const TABS = [
  { code: 'LEVEL_1', label: 'Level 1' },
  { code: 'LEVEL_3', label: 'Level 3' },
  { code: 'LEVEL_5', label: 'Level 5' },
];

const GAMES = [
  { code: 'ONE_TO_FIVE', label: 'Số 1 ~ 5' },
  { code: 'TONG_HOA', label: 'Tổng hoà' },
];

const GATES = [
  { code: 'CHUC_NGAN_', label: 'C.Ngàn' },
  { code: 'NGAN_', label: 'Ngàn' },
  { code: 'TRAM_', label: 'Trăm' },
  { code: 'CHUC_', label: 'Chục' },
  { code: 'DON_VI_', label: 'Đơn vị' },
];

const Home = () => {
  const { currentSessions, lastSessions } = useSelector(systemSelector);

  const [activeZone, onTabChange] = useTabs(TABS);
  const [activeGame, setActiveGame] = useState(GAMES[0].code);
  const [activeGate, setActiveGate] = useState(GATES[0].code);
  const [activeItem, setActiveItem] = useState('');

  const [currentSession, lastSession] = useMemo(
    () => [currentSessions[activeZone], lastSessions[activeZone]],
    [currentSessions, lastSessions, activeZone],
  );

  const [openInfo, setOpenInfo] = useState(false);
  const [openBet, setOpenBet] = useState(false);
  const isOneToFive = activeGame === 'ONE_TO_FIVE';

  return (
    <div className='flex flex-col w-full'>
      <div className='min-h-[60px] flex justify-between items-center px-3'>
        <AppLogo size={40} />
        <div className='text-white font-medium'>
          Kết quả kỳ
          <span className='bg-grey-gradient font-bold px-2 py-0.5 mx-1 rounded-full shadow-base'>
            {lastSession?.incId}
          </span>
          hôm nay
        </div>
        <Tooltip title='Quy định'>
          <IconButton size='small' onClick={() => setOpenInfo(true)}>
            <InfoOutlined className='text-white' />
          </IconButton>
        </Tooltip>
      </div>

      <div className='flex justify-center space-x-3 my-3'>
        {lastSession.result.split('').map((number, index) => (
          <Avatar key={index} className='bg-grey-gradient font-bold shadow-base'>
            {number}
          </Avatar>
        ))}
      </div>

      <div className='flex justify-between text-white px-3 mb-3'>
        <div>
          Kỳ tiếp theo: <span className='font-medium'>{currentSession?.incId}</span>
        </div>
        <div className='flex items-center space-x-1'>
          <AccessTimeOutlined />
          <Countdown
            overtime
            date={currentSession.endTime}
            renderer={({ formatted: { minutes, seconds } }) => (
              <span className='font-medium'>
                {minutes}:{seconds}
              </span>
            )}
          />
        </div>
      </div>
      <div className='border-t py-1 relative pb-[28px]'>
        <Tabs value={activeZone} onChange={onTabChange} variant='fullWidth'>
          {TABS.map((tab) => (
            <Tab key={tab.code} label={tab.label} value={tab.code} />
          ))}
        </Tabs>

        <Paper
          elevation={0}
          className='shadow-base rounded-full p-2 absolute left-0 right-0 w-[90%] mx-auto bottom-[-24px] z-10'
        >
          <div className='flex justify-center space-x-2'>
            {GAMES.map((item, index) => (
              <Button
                key={index}
                variant={activeGame === item.code ? 'contained' : 'text'}
                color={activeGame === item.code ? 'info' : 'inherit'}
                className='w-[120px] min-h-[36px] text-sm'
                onClick={() => setActiveGame(item.code)}
              >
                {item.label}
              </Button>
            ))}
          </div>
        </Paper>
      </div>

      <div className='flex-1 bg-white p-[12px] pt-[36px]'>
        {isOneToFive && (
          <>
            <div className='flex justify-center mb-2'>
              <ButtonGroup variant='text' color='info'>
                {GATES.map((item, index) => (
                  <Button
                    key={index}
                    variant='outlined'
                    className={activeGate === item.code ? 'bg-info-gradient' : 'bg-white'}
                    style={{ borderLeftWidth: index ? 0 : 1 }}
                    onClick={() => setActiveGate(item.code)}
                  >
                    {item.label}
                  </Button>
                ))}
              </ButtonGroup>
            </div>
            <Grid container className='border-t'>
              {ONE_TO_FIVE.map((item, index) => (
                <Grid
                  item
                  xs={3 * item.colspan}
                  key={index}
                  className='border border-t-[0px] text-center pt-1 pb-2 cursor-pointer hover:bg-black/5'
                  style={{ borderLeft: index % (item.cross > 2 ? 4 : 2) ? 'none' : '' }}
                  onClick={() => {
                    setOpenBet(true);
                    setActiveItem(activeGate + item.gate);
                  }}
                >
                  <div className='text-secondary-main text-[24px] font-medium'>{item.label}</div>
                  <div className='text-neutral'>{item.cross}</div>
                </Grid>
              ))}
            </Grid>
          </>
        )}
        {isOneToFive || (
          <Grid container>
            <Grid item xs={12} className='bg-info-gradient font-bold text-center rounded-t-[8px] p-2'>
              Tổng 3 hàng cuối cùng
            </Grid>
            <Grid container>
              {TONG_HOA.map((item, index) => (
                <Grid
                  item
                  xs={3}
                  key={index}
                  className='border border-t-[0px] text-center pt-1 pb-2 cursor-pointer hover:bg-black/5'
                  style={{ borderLeft: index % 4 ? 'none' : '' }}
                  onClick={() => {
                    setOpenBet(true);
                    setActiveItem(item.gate);
                  }}
                >
                  <div className='text-secondary-main text-[24px] font-medium'>{item.gate}</div>
                  <div className='text-neutral'>{item.cross}</div>
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
      </div>

      <Dialog open={openInfo} fullScreen>
        <PopupInfo onClose={() => setOpenInfo(false)} />
      </Dialog>
      <Dialog open={openBet} onClose={() => setOpenBet(false)}>
        <PopupBet
          onClose={() => setOpenBet(false)}
          item={{
            zone: activeZone,
            game: activeGame,
            gate: activeItem,
          }}
        />
      </Dialog>
    </div>
  );
};

export default Home;
